

.img-fluid{
    width: 100%;
}
.place{
    .owl-nav{
        .owl-prev,.owl-next{
            background: white;
            box-shadow: 0px 0px 10px -3px #00000052;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        path{
            fill: #606060!important;
        }
    }
    .slick-list{
        width: 100%;
        box-shadow: inherit;
    }
    .slick-slide > div {
        display: flex;
        align-items: center;
    }
    .slick-arrow{
        font-size: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 100%;
        outline: 0;
        border: 0;
        box-shadow: 0px 0px 10px 0px #00000024;
        background: white;
        z-index: 2;
    }
    .slick-prev{
        left: -1.5rem;
        position: absolute;
        @media(max-width:740px){
            left: -6vw;
        }

    }
    .slick-next{
        position: absolute;
        right: -1.7rem;
        @media(max-width:740px){
            right: -6vw;
        }
    }
    .slick-prev::after{
        content: "";
        width: 30%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: .2rem;
        margin: auto;
        mask: url("../../Assets/images/iconos/signalLeft.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--green);
    }
    .slick-next::after{
        content: "";
        width: 30%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: -.2rem;
        margin: auto;
        mask: url("../../Assets/images/iconos/signalRight.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background: var(--green);
    }
}
.owl-item > div{
    display: flex;
    width: 100%;
}
.slick-slider{
    height: 100%;
    .slick-list,.slick-track,.slick-slide > div{
        height: 100%;
    }
    .slick-prev{
        position: absolute;
    }
}
.slick-list{
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0px 0px 13px 0px #00000026;
    .slick-track{
        display: flex;
    }
}
@mixin mask($url){
    mask: $url;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
}
.skyna{
    --maincolor:#A9EEC2;
    --textpage:#141414;
}
.garden{
    --maincolor:#A9EEC2;
    --textpage:#141414;
}
.avantte{
    --maincolor:#ff4d0f;
    --textpage:white;
}
#map{
    position: relative;
    height: 30rem;
    overflow: hidden;
}
.Page-model{
    width: 100%;
    --text:#353535;
    --green:#A9EEC2;
    *{
        color:var(--text)
    }
    .section-caracteristics,.multimedia,.section-tipologia,.place,.contactanosModel{
        width: 100%;
        padding: 6vw 6vw;
        @media(min-width:700px){
            padding: 0 1rem;
        }
    }

    .section-caracteristics{
        display: flex;
        flex-direction: column;
        .flex-caracteristics{
            display: flex;
            flex-direction: column;
        }
        .title-1{
            font-size: 5vw;
            letter-spacing: 1vw;
            margin-left: 4vw;
        }
        .title-2{
            font-size: 8vw;
            margin: 1vw 0vw;
            font-weight: bold;
            margin-left: 4vw;
        }
        .title-3{
            font-size: 9vw;
            margin-left: 4vw;
        }
        .content-caracteristics{
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #3535353b;
            border-radius: 4vw;
            margin-top: 5vw;
            .caracteristic{
                padding: 4vw 0vw 4vw 3vw;
                width: 50%;
                display: flex;
                .item{
                    display: flex;
                }
                .precio{
                    mask: url("../../Assets/images/iconos/svg/money.svg");
                }
                .facilidad{
                    mask: url("../../Assets/images/iconos/svg/pig.svg");
                }
                .ubicacion{
                    mask: url("../../Assets/images/iconos/svg/place-biabo.svg");
                }
                .acabados{
                    mask: url("../../Assets/images/iconos/svg/new-decoration.svg");
                }
                .parrilla{
                    mask: url("../../Assets/images/iconos/svg/parrilla-biabo.svg");
                }
                .diamont{
                    mask: url("../../Assets/images/iconos/svg/diamont-garden.svg");
                }
                .safety{
                    mask: url("../../Assets/images/iconos/svg/diamont-garden.svg");
                }
                .diamont{
                    mask: url("../../Assets/images/iconos/svg/safety.svg");
                }
                .lock{
                    mask: url("../../Assets/images/iconos/svg/lock.svg");
                }
                .icon-item{
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    height: 10vw;
                    width: 13vw;
                    margin-right: 2vw;
                    background: var(--Primary);
                    .title-icon{
                        font-size: 4vw;
                    }
                }
                .title-icon{
                    font-size: 4.1vw;
                }
            }
            .caracteristic:nth-child(-n+2){
                border-bottom:1px solid #3535353b;
            }
            .caracteristic:nth-child(odd){
                .item{
                    border-right: 1px solid #3535353b;
                }
            }
        }
        .content-img{
            margin-top: 3vw;
            .overflow-img{
                width: 100%;
                overflow: hidden;
                border-radius: 1.5rem;
                position: relative;
                display: flex;
                .title{
                    background: black;
                    width: auto;
                    font-size: 1.4rem;
                    border-radius: 1rem 0 0 0;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--green);
                    padding: .3rem 1.3rem;
                }
            }
        }
    }
    .multimedia-carousel{
        position: absolute;
    }
    .multimedia{
        padding: 6vw 6vw;
        .slick-arrow{
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }
        .content-title{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .title-multimedia{
                font-size: 4.5vw;
                padding: 5vw;
                display: block;
                position: relative;
                transition: 0.5s;
                cursor: pointer;
                user-select: none;
            }
            .titles{
                display: flex;  
            }
            .title-multimedia.active{
                font-weight: bold;
            }
            .title-multimedia.active::after{
                content: "";
                width: 7vw;
                height: 1vw;
                background: var(--green);
                border-radius: 40rem;
                position: absolute;
                bottom: 3vw;
                left: 0;
                right: 0;
                margin: auto;
                animation: .5s barraX;
                transform-origin: 0% 100%;
            }
            @keyframes barraX{
                0%{
                    transform: scaleX(0);
                }
                100%{
                    transform: scaleX(1);
                }
            }
        }
        .slide{
            position: relative;
            height: 100%;
            h4{
                position: absolute;
                visibility: hidden;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            [data-icon="bath"]{
                object-position: 62% 61%;
            }
        }
        .content-slider{
            border-radius: 4vw;
            overflow: hidden;
            width: 100%;
            height: 91vw;
            position: relative;
            cursor:pointer;
            .interiores-carousel,.areascomunes-carousel {
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: 0;
                transition: 1s;
                top: 0;
            }
            .interiores-carousel.active,.areascomunes-carousel.active{
                position: relative;
                z-index: 1;
                opacity: 1;
            }
            .img-open{
                width: 10vw;
                height: 10vw;
                border-radius: 100%;
                background: white;
                position: absolute;
                bottom: 2vw;
                right: 2vw;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 50%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .recorrido-virtual{
            position: relative;
            display: block;
            width: 100%;
            height: 30vw;
            border-radius: 4vw;
            overflow: hidden;
            margin-top: 4vw;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .content-title-recorrido{
                position: absolute;
                background: linear-gradient(262deg, rgba(0,0,0,0.20211834733893552) 0%, rgba(0,0,0,0.577468487394958) 100%);
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 6vw;
                .title-recorrido{
                    color: white;
                    font-size: 4vw;
                    letter-spacing: 1vw;
                }
                .icon-recorrido{
                    width: 15vw;
                    height: 15vw;
                    mask:url("../../Assets/images/iconos/svg/iframe-biabo-360.svg");
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background: white;
                    float: right;
                }
            }
        }
    }
    .section-tipologia{
        .title{
            font-size: 8vw;
            margin-bottom: 8vw;
            font-family: "GtWalsheimProBold";
        }
    }
    .place{
        overflow: hidden;
        margin-top: 5vw;
        padding-bottom: 2vw;
        position: relative;
        @media(min-width:740px){
            margin-bottom: 3rem;
        }
        .owl-nav.disabled{
            display: block;
            .owl-prev,.owl-next{
                height: 9vw;
                width: 9vw;
                background: white;
                box-shadow: 0px 0px 10px -3px #00000052;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .owl-prev{
                padding: 2.2vw 3vw 2.2vw 2vw;
                left: -4%;
            }
            .owl-next{
                padding: 2.2vw 2vw 2.2vw 3vw;
                right: -4%;
            }
        }
        .content-place{
            width: 100%;
            display: flex;
            justify-content: space-around;
            
            .content-icon{
                width: 15vw;
                height: 15vw;
                background: #CACACA;
                position: relative;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon{
                    width: 74%;
                    height: 51%;
                    background: white;
                    pointer-events: none;
                }
            }
            .content-icon::after{
                content: "";
                width: 0;
                height: 0;
                border-left: 3vw solid #CACACA;
                border-top: 2.5vw solid transparent;
                border-bottom: 2.5vw solid transparent;
                position: absolute;
                bottom: -3vw;
                left: 0;
                right: 0;
                margin: auto;
                transform: rotate(90deg);
            }
            .content-icon.active{
                background: var(--green);
                .icon{
                    background: black;
                }
            }
            .content-icon.active::after{
                border-left: 3vw solid var(--green);
            }
        }
        .content-title{
            width: 26rem;
            @media(max-width:740px){
                width: 100%;
            }
        }
    }
    .educacion{
        @include mask(url("../../Assets/images/iconos/svg/educacion.svg"))
    }
    .universidades{
        @include mask(url("../../Assets/images/iconos/svg/universidades.svg"))
    }
    .bateria{
        @include mask(url("../../Assets/images/iconos/svg/bateria.svg"))
    }
    .restaurantes{
        @include mask(url("../../Assets/images/iconos/svg/restaurantes.svg"))
    }
    .title-place-1{
        margin-top: 5vw;
        display: block; 
        font-size: 5vw;
        letter-spacing: 1.5vw;   
    }
    .title-place{
        font-size: 6vw;
    }
    .slider-ubicacion{
        width: 94%;
        margin: 0 auto;
        position: relative;
        .owl-item{
            display: flex;
            align-items: center;
            justify-content: center;

        }
        .type{
            height: 8rem;
            display: flex;
            // overflow-x: scroll;
            // overflow-y: hidden;
            align-items: center;
            position: absolute;
            opacity: 0;
        }
        .type.active{
            position: relative;
            opacity: 1;
        }
        .owl-nav.disabled{
            polyline{
                stroke: var(--green);
            }
        }
        .slide-place{
            width: 40.5vw;
            min-width:33.5vw;
            height: 14vw;
            background: white;
            box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.377);
            border-radius: 50rem;
            display: flex;
            padding: 1vw 2vw;
            margin: 3vw 1vw;
            display: flex!important;
            justify-content: center;
            .content-title{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .title{
                    font-size: 3vw;
                    display: block;
                    text-align: center;
                    display: block;
                    line-height: 1;
                }
                .sub-title{
                    display: block;
                    text-align: center;
                    font-size: 2.5vw;
                }
            }
        }
    }
    .contactanosModel{
        background: #F0F0F0;
        padding: 1vw 3vw;
        width: 92%;
        margin: auto;
        margin-top: 3vw;
        margin-bottom: 5vw;
        padding:1rem 0;
        .title-contactModel{
            font-size: 8vw;
        }
    }
}

@media(min-width:700px){
.Page-model{
    .section-caracteristics{
        --titleright: 2.5rem;
        flex-direction: row-reverse;
        justify-content: center;
        margin-top: 5rem;
        .title-1{
            font-size: 1.1rem;
            letter-spacing: .3rem;
            margin-left: var(--titleright)
        }
        .title-2{
            font-size: 2.1rem;
            margin-left: var(--titleright)
        }
        .title-3{
            font-size: 4rem;
            margin-left: var(--titleright)
        }
        .flex-caracteristics{
            justify-content: center;
        }
        .content-caracteristics{
            border-radius: 1.2rem;
            margin-top: 1rem;
            align-items: center;
            justify-content: center;
            width: 370px;
            @media(min-width:960px){
                width: 450px;
            }
            .caracteristic{
                padding: .5rem 0vw .5rem .5rem;
                .item{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                }
                .title-icon{
                    font-size: 1.1rem;
                    width: 100%;
                }
                .icon-item{
                    height: 2.5rem;
                    width: 2rem;
                    min-width: 2.5rem;
                    margin-right: .8rem;
                }
            }
        }
        .content-img{
            width: 24rem;
            margin-right: 2rem;
            margin-top: 0;
           
        }

    }
    .multimedia{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        padding: 0 1rem;
        margin-top: 7rem;
        .owl-item.active{
            .img-open{
                animation: 2s moveX forwards;
            }
            @keyframes moveX {
                0%{
                    transform: translateX(150%);
                    opacity: 0;
                }
                100%{
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
        .content-slider{
            height: 23rem;
            width: 53%;
            border-radius: 0;
            overflow: inherit;
            @media(min-width:1200px){
                height: 24rem;
            }
            .MyCarouselHorizontal{
                border-radius: 1.5rem;
                overflow: hidden;
            }
            .icon-signal{
                width: 5rem;
                height: 5rem;
                position: absolute;
                z-index: 10;
                top: 0;
                left: 1.5rem;
                border-radius: 0 0 1rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 13px 20px -6px #00000085;
                .icon{
                    height: 3rem;
                    width: 3rem;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    mask-position: center;
                    background: var(--dark);
                    transition: .2s;
                }
                #sofa{
                    mask-image: url("../../Assets/images/skyna/iconos/salacomedor.svg");
                }
                #cocina{
                    mask-image: url("../../Assets/images/iconos/svg/cocina.svg");
                }
                #dormitorio{
                    mask-image: url("../../Assets/images/iconos/svg/cama.svg");
                }
                #bath{
                    mask-image: url("../../Assets/images/iconos/svg/icon-bath.svg");
                }
                #lobby{
                    mask-image: url("../../Assets/images/iconos/svg/lobby.svg");
                }
                #pesa{
                    mask-image: url("../../Assets/images/iconos/svg/pesa.svg");
                }
                #boliche{
                    mask-image: url("../../Assets/images/iconos/svg/boliche.svg");
                }
                #juegos{
                    mask-image: url("../../Assets/images/iconos/svg/boliche.svg");
                }
                #parrilla{
                    mask-image: url("../../Assets/images/iconos/svg/parrilla.svg");
                }
                #child{
                    mask-image: url("../../Assets/images/iconos/svg/child.svg");
                }
            }
            .img-open{
                height: 2.5rem;
                bottom: 1rem;
                right: 1.5rem;
                background: transparent;
                width: auto;
                .title-img{
                    color: white;
                    font-size: 1.8rem;
                    text-shadow: -1px 2px 6px black;
                }
            }
            .controllers{
                position: absolute;
                z-index: 10;
                display: flex;
                bottom: -1.2rem;
                left: 3rem;
                transition: .5s;
                cursor: pointer;
                .icon{
                    width: 2.5rem;
                    height: 2.5rem;
                    background: var(--dark);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0s;
                    .left,.right{
                        width: 100%;
                        height: 50%;
                        background: white;
                    }
                    .left:hover,.right:hover{
                        background: var(--green);
                    }
                }
                .icon-left{
                    border-radius: .6rem 0 0 .6rem;
                }
                .icon-right{
                    border-radius: 0 .6rem .6rem 0;
                }
                
            }
            .controllers:hover{
                transform: scale(1.03);
                box-shadow: 
                    0 0 6px 0px  rgba(0, 0, 0, 0.185),
                    0 0 10px 0px   rgba(0, 0, 0, 0.404),
                    0 0 20px 0px  rgba(0, 0, 0, 0.603);
                border-radius: .6rem;
            }
        }
        .content-title{
            margin-left: 3%;
            width: 44%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // padding: 0 0 0 1rem;
            @media(min-width:960px){
                // padding: 0 0 0 2rem;
            }
            .titles{
                display: flex;
                width: 100%;
                .title-multimedia:nth-child(1){
                    margin-right: 1.5rem;
                }
            }
            .title-multimedia{
                text-align: center;
                width: 50%;
                padding: 0rem;
                font-size: 1rem;
                @media(min-width:960px){
                    padding: 1.5rem;
                }
                @media(min-width:1200px){
                    font-size: 1.2rem;
                    padding: 2.5rem;
                }
                
            }
            .title-multimedia.active::after{
                width: 2rem;
                height: .4rem;
                bottom: -0.5rem;
                @media(min-width:960px){
                    bottom: 0.5rem;
                }
                @media(min-width:1200px){
                    bottom: 1.5rem;
                }
            }
            .controller-slider{
                width: 100%;
                display: flex;
                .button-slide{
                    display: flex;
                    .btn-left,.btn-right{
                        width: 2.5rem;
                        height: 5rem;
                        background: var(--dark);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .icon-miniaturas{
                height: 5rem;
                width: 100%;
                overflow: hidden;
                position: relative;
                .icon{
                    width: 33.33%;
                    height: 100%;
                    background: red;
                }
            }
            .miniaturas{
                width: 100%;
                display: flex;
                justify-content: center;
                
                .miniatura-areas-comunes,.miniatura-interior{
                    z-index: 0;
                    width: 100%;
                    height: 9rem;
                    // border-radius: .8rem;
                    overflow: hidden;
                    position: relative;
                    border-radius: 1rem;
                    overflow: hidden;
                    .expand-lupa{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(255, 255, 255, 0.383);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        visibility: visible;
                        .content-lupa{
                            position: relative;
                            width: 3rem;
                            height: 3rem;
                            background: white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.335);
                            cursor: pointer;
                            .img{
                                height: 30%;
                            }
                        }
                    }
                    .expand-lupa.active{
                        visibility: hidden;
                    }
                }
                .miniatura-interior{
                    margin-right: 3%;
                }
                .miniatura-areas-comunes.active,.miniatura-interior.active{
                    position: relative;
                    z-index: 1;
                    opacity: 1;
                }
            }
        }
        .recorrido-virtual{
            width: 100%;
            height: 7rem;
            border-radius: 1rem;
            margin-top: -.5rem;
            @media(min-width:960px){
                margin-top: .9rem;
            }
            @media(min-width:1200px){
                margin-top: 1.2rem;
            }
            .content-title-recorrido{
                padding: 0 2rem;
                background: linear-gradient(262deg, rgba(0, 0, 0, 0.315) 0%, rgba(0, 0, 0, 0.692) 100%);
                .title-recorrido{
                    font-size: 1.2rem;
                    letter-spacing: .5rem;
                }
                .icon-recorrido{
                    width: 16%;
                    height: 100%;
                }
            }
        }
    }
    .section-tipologia{
        margin-top: 4rem;
        @media(min-width:960px){
            margin-top: 7rem;
        }
        .title{
            font-size: 2.5rem;
            margin-bottom: 9rem;
        }
        .content-slider{
            margin-bottom: 7rem;
            @media(min-width:960px){
                margin-bottom: 9rem;
            }
        }
    }
    .ubicacion{
        display: flex;
        height: auto;
    }
    .place{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0rem;
        padding: 0;
        @media(min-width:960px){
            padding: 0 1rem;
            margin-top: 9rem;
        }
        @media(min-width:1200px){
            margin-top: 12rem;
        }
        .owl-nav.disabled{
            .owl-prev,.owl-next{
                width: 2rem;
                height: 2rem;
                padding: .5rem;
            }
        }
        
        .content-place{
            justify-content: normal;
            width: auto;
            margin-right: 2rem;
            .content-icon{
                width: 3.5rem;
                height: 3.5rem;
                cursor: pointer;
                margin-right: 1rem;
                @media(min-width:960px){
                    width: 4.5rem;
                    height: 4.5rem;
                }
            }
            .content-icon::after{
                border-top: .6rem solid transparent;
                border-bottom: .6rem solid transparent;
                border-left: 1.3rem solid transparent;
                bottom: -.9rem;
                @media(min-width:960px){
                    border-top: 1rem solid transparent;
                    border-bottom: 1rem solid transparent;
                    border-left: 0.7rem solid transparent;
                    bottom: -.8rem;
                }
            }
            .content-icon.active::after{
                border-left: .8rem solid var(--green);
                @media(min-width:960px){
                    border-left: 1.5rem solid var(--green);
                }
            }
        }
        .title-place{
            font-size: 1.5rem;
            @media(min-width:960px){
                font-size: 1.8rem;
            }
        }
        .title-place-1{
            font-size: 1.4rem;
            @media(max-width:960px){
                margin-top: 2.4rem;
            }
            @media(min-width:960px){
                font-size: 1.6rem;
            }
            letter-spacing: .2rem;
            margin-top: 0.4rem;
        }
    }
    .slider-ubicacion{
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 1rem;
        @media(min-width:960px){
            width: 87%;
        }
        @media(min-width:1400px){
            width: 92%;
        }
        .type{
            display: flex;
            // overflow-x: scroll;
            // overflow-y: inherit;
            position: absolute;
            width: 100%;
            opacity: 0;
            z-index: 0;
            padding: 0 1rem;
        }
        .type.active{
            position: relative;
            opacity: 1;
            z-index: 1;
        }
        // .type::-webkit-scrollbar {
        //     width: 10rem;
        // }
        // .type::-webkit-scrollbar-thumb {
        //     background: var(--text);
        //     border-radius: 40rem 40rem;
        // }
        // .type::-webkit-scrollbar-track {
        //     background: #06337733;
        //     border-radius: 40rem 40rem ;
        // }
        .slide-place{
            height: 5.5rem;
            padding: .5rem .9rem;
            width: 10.3rem;
            min-width: 10.3rem;
            margin-right: 1rem;
            margin-top: .5rem;
            margin-bottom: .5rem;
            @media(min-width:960px){
                width: 11rem;
                min-width: 14rem;
                max-width: 14rem;
            }
            @media(min-width:1200px){
                width: 14rem;
                min-width: 14rem;
                max-width: 14rem;
            }
            .content-title{
                .title{
                    font-size: .8rem;
                    line-height: 1;
                    @media(min-width:960px){
                        font-size: 1rem;
                    }
                    @media(min-width:1200px){
                        font-size: 1rem;
                    }
                    @media(min-width:1500px){
                        font-size: 1.2rem;
                    }
                }
                .sub-title{
                    font-size: .7rem;
                    @media(min-width:960px){
                        font-size: .8rem;
                    }
                    @media(min-width:1200px){
                        font-size: .9rem;
                    }
                }
            }
        }
    }
    
}
}
.garden{
    --maincolor:#1ce5b0;
    .place{
        @media(min-width:700px){
            margin-top: 8rem;
        }
    }
    .place{
        .content-place{
            .content-icon.active{
                background:var(--maincolor) ;
                .icon{
                    background: white;
                }
            }
            .content-icon.active::after{
                border-left-color:var(--maincolor);
            }
        }
    }
    .Slider-tipologias{
        .title,.content-slider{
            @media(min-width:700px){
              
            }
            margin-bottom: 2rem !important;
        }
        .tipologia{
            .img-tipologia{
                img{
                    transform: rotate(180deg);
                }
            }
        }
       
        .tipologia-main{
            .img-main-tipologia{
                display: flex;
                align-items: center;
                right: 0;
                padding-bottom: 6vw;
                height: 75vw;
                @media(min-width:700px){
                    height: auto;
                    top: -3rem;
                    height: 19rem;
                    right: 2rem;
                    padding-bottom: 0vw;
                }
                .img{
                    transform: rotate(180deg);
                    filter: drop-shadow(-0.8rem -0.8rem 1rem rgba(0, 0, 0, 0.3));
                    @media(min-width:960px){
                        width: 37rem;
                    }
                }
            }
        }
    }
    .section-caracteristics{
        .content-caracteristics{
            .caracteristic{
                .item{
                    .icon-item{
                        background:var(--maincolor) ;
                    }
                }
            }
        }
        .content-img{
            .overflow-img{
                .title{
                    background:var(--maincolor) ;
                    color:  white;
                }
            }
        }
    }
    .multimedia{
        .content-title{
            .title-multimedia.active::after{
                background: var(--maincolor);
            }
        }
        .content-slider{
            .icon-signal{
                background: var(--maincolor);
                .icon{
                    background: white;
                }
            }
            .controllers{
                .icon{
                    .right:hover,.left:hover{
                        background: var(--maincolor);
                    }
                }
            }
        }
    }
    .contactanosModel{
        .formulario-model{
            button[type=submit]{
                background: var(--maincolor);
                color: white;
            }
        }
    }
}
.avantte{
    .place{
        .content-place{
            .content-icon.active{
                background:var(--maincolor) ;
                .icon{
                    background: white;
                }
            }
            .content-icon.active::after{
                border-left-color:var(--maincolor);
            }
        }
    }
    .section-caracteristics{
        .content-caracteristics{
            .caracteristic{
                .item{
                    .icon-item{
                        background:var(--maincolor) ;
                    }
                }
            }
        }
        .content-img{
            .overflow-img{
                .title{
                    background:var(--maincolor) ;
                    color:  white;
                }
            }
        }
    }
    .Slider-tipologias{
        .owl-prev:hover,.owl-next:hover{
            polyline{
                stroke: white!important;
            }
        } 
        .tipologia-main{
            .title-main-tipologia{
                @media(max-width:700px){
                    font-size: 9vw;
                }
            }
            .content-info-tipologia{
                .map{
                    img{
                        background:white;
                    }
                }
            }
        }
    }
    .multimedia{
        .content-title{
            .title-multimedia.active::after{
                background: var(--maincolor);
            }
        }
        .content-slider{
            .icon-signal{
                background: var(--maincolor);
                .icon{
                    background: white;
                }
            }
            .controllers{
                .icon{
                    transition: 0s!important;
                    .right:hover,.left:hover{
                        background: var(--maincolor);
                    }
                }
            }
        }
    }
    .contactanosModel{
        .formulario-model{
            button[type=submit]{
                background: var(--maincolor);
                color: white;
            }
        }
    }
}
.skyna{
    .multimedia{
        .content-slider{
            .icon-signal{
                background: var(--green);
                .icon{
                    background: var(--dark);
                }
            }
        }
    }
    .Slider-tipologias{
        .tipologia-main{
            .title-main-tipologia{
                @media(max-width:700px){
                    bottom: 3vw;
                    font-size: 11vw;
                }
            }
        }
        .tipologia{
            .content-info{
                .tipo{
                    @media(max-width:700px){
                        font-size: 3.5vw;
                    }
                }
                .meters{
                    @media(max-width:700px){
                        font-size: 3.5vw;
                    }
                }
            }
        }
    }
    .contactanosModel{
        .formulario-model{
            button[type=submit]{
                background: var(--maincolor);
                color: var(--dark);
            }
        }
    }
}