.AboutUs{
    *{
        color: var(--Secondary);
    }
    margin-top: 8rem;
    .title{
        font-size: 5vw;
        letter-spacing: 0.5vw;
        display: block;
        text-align: center;
    }
    .paragraph{
        width: 87vw;
        margin: auto;
        text-align: center;
        margin-top: 3vw;
        margin-bottom: 4vw;
        font-size: 3.8vw;
    }
    .info-aboutus{
        margin-bottom: 10vw;
    }
    .content-slider{
        padding: 7vw 7vw;
        background: #F7F7F7;
        position: relative;
        .title{
            padding: 3vw 0;
        }
        .card{
            position: relative;
            width: 100%;
            height: 110vw;
            padding: 3vw;
            .shadow-card{
                width: 100%;
                height: 100%;
                border-radius: 4vw;
                overflow: hidden;
                box-shadow: 0px 0px 10px -1px rgba(10, 10, 10, 0.308);
            }
            .content-img{
                width: 100%;
                height: 75%;
                position: relative;
                .place{
                    position: absolute;
                    bottom: 0;
                    background: var(--Secondary);
                    color: white;
                    height: 10vw;
                    width: 28vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5vw 6vw 0 0vw;
                    font-size: 4.5vw;
                    font-weight: bold;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
            .content-title-card{
                height: 25%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 2vw;
            }
            .title-card{
                font-size: 6vw;
            }
            .sub-title-card{
                font-size: 4vw;
            }
        }
        .owl-nav{
            display: block;
            .owl-prev,.owl-next{
                width: 11vw;
                height: 11vw;
                background: white;
                color: white;
                border-radius: 100%;
                box-shadow: 0px 0px 10px 0px #0000002e;
                padding: 3.5vw;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .owl-prev{
                left: -4%;
            }
            .owl-next{
                right: -4%;
            }
        }
    }
}
@media(min-width:700px){
.AboutUs{
    display: flex;
    margin-top: 0;
    position: relative;
    height: auto;
    @media(min-width:960px){
        min-height: 700px;
    }
    @media(min-width:1100px){
        height: 100vh;
        min-height: 850px;
    }
    @media(min-width:1400px){
        min-height: 1000px;
        padding-bottom: 10rem;
        padding-top: 10rem;
    }
    @media(min-width:1500px){
        min-height: 1000px;
    }
    .content-about{
        display: flex;
        width: 100%;
        padding-left: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
        @media(min-width:1100px){
            padding-left: 2rem;
            flex-direction: row;
            display: flex;
        }
    }
    .info-aboutus{
        max-width: 400px;
        width: 50%;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 3rem;
        .title{
            font-size: 1.7rem;
            letter-spacing: .2rem;
            text-align: left;
        }
        .paragraph{
            width: 100%;
            font-size: 1.2rem;
            margin-top: 1rem;
            margin-bottom: 0rem;
            text-align: left;
        }
    }
    .container{
        display: flex;
    }
    .content-slider{
        width:700px;
        padding: 0;
        padding-left: 0;
        background: transparent;
        @media(min-width:1100px){
            padding-left: 4rem;
        }
        @media(min-width:1200px){
            width: 800px;
        }
        @media(min-width:1500px){
            width: 900px;
        }
        .title{
            display: none;
        }

        .owl-nav{
            .owl-next,.owl-prev{
                width: 4rem;
                height: 4rem;
                padding: 1.2rem
            }
            .owl-next{
                right: -5%;
            }
        }
        .card{
            width: 100%;
            padding: .1rem;
            height: 29rem;
            @media(min-width:1100px){
                height: 20rem;
            }
            @media(min-width:1200px){
                height: 28rem;
            }
            .shadow-card{
                border-radius: .9rem;
            }
            .content-img{
                .place{
                    font-size: 1.1rem;
                    width: 7rem;
                    height: 2.5rem;
                    border-radius: 1rem 1rem 0 0vw;
                    font-size: 1.1rem;
                    width: fit-content;
                    height: 2.5rem;
                    padding: 0 1.5rem;
                    border-radius: 1rem 1rem 0 0vw;
                }
            }
            .title-card{
                font-size: 1.2rem;
            }
            .sub-title-card{
                font-size: .9rem;
            }
            .content-title-card{
                background: white;
                padding: 0;
                @media(min-width:1200px){
                    padding: 1rem;
                }
            }
        }
    }
}
.AboutUs::before{
    @media(min-width:1100px){
        content: "";
        background: rgb(240, 240, 240);
        width: 40%;
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        z-index: -1;
    }
}
}