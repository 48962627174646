.swal2-styled.swal2-confirm{
    background: #00224E;
}
.loading{
    background: var(--Secondary);
    z-index: 99999;
    height: 100vh;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: loading 4s forwards;
    transition:0s;
    .biabo{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6vw;
        svg{
            height: 100%;
        }
        img{
            height: 100%;
        }
    }
    .animacion{
        height: 15vw;
        width: 31vw;
        background: linear-gradient(90deg, rgba(28, 226, 173, 0) 0%, #00224E 31%);
        position: absolute;
        animation: showBiabo 6s forwards  linear;
        left: -17vw;
        right: 0vw;
        margin: auto;
    }
}
.loading.none{
    display: none;
}
@keyframes loading{
    0%{
        z-index: 99999;
    }
    90%{
        opacity: 1;
    }
    99%{
        opacity: 0;
        z-index: 99999;
    }
    100%{
        opacity: 0;
        z-index: -1;
        visibility: hidden;
    }   
}

@keyframes showBiabo{
    0%{
        left: -17vw;
    }
    100%{
        left: 80vw;
    }
}
@media (orientation:portrait){
    .loading{
        .biabo{
            height: 20vw;
            margin-bottom: 20vw;
        }
        .animacion{
            height: 50vw;
            width: 78vw;
        }
    }
}