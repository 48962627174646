@mixin flex-center(){
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin  background($url){
    background: url($url);
    background-repeat: no-repeat;
    background-position: 100%;
}
.Proyectos{
    width: 100%;
    .Content_proyectos{
        width: 100%;
        height: auto;
        color: var(--Secondary);
        .selectores{
            height: 31%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            #todos{
                display: none;
                font-size: 4.5vw;
                font-family: "MarkProMedium";
                
            }
            #todos-desktop{
                display: inline-flex;
            }
            .title{
                  padding-top: 6vw;
                padding-bottom: 2vw;

                font-size: 2.2vw;
            }
            .selector-title{
                width: 90%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                height: 50%;
                color: var(--Third);
                .selector{
                    font-size: 1.2vw;
                    margin: 0 1vw;
                    position: relative;
                    cursor: pointer;
                }
                .selector:hover{
                    color: var(--Primary);
                }
                .selector.active::before{
                        width: 100%;
                        background: var(--Primary);
                }
                .selector:hover::before{
                    background: var(--Primary);
                    width: 100%;
                }
                .selector::before{
                    content: "";
                    height:.2vw;
                    width: 0%;
                    display: block;
                    position: absolute;
                    bottom: -.5vw;
                    transition: .5s;
                    
                }
            }
        }
        .builds{
            height: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 0vw 1vw;
            margin-bottom: 2vw;
            .owl-carousel{
                padding-top: 1vw;
            }
            .items{
                height: auto;
                width: 21.55vw;
                margin: 0 1.1vw;
                position: relative;
                margin-bottom: 3vw;
                top:1vw;
                transition: 0.5s;
                cursor: pointer;
                display: block;
                .build{
                    height:auto;
                    width: 100%;
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    .build-header{
                        height: 7vw;
                        width: 100%;
                        position: relative;
                        padding: .5vw 0 .8vw 4vw;

                        .brand-header{
                            height: 100%;
                            img{
                                height: 100%;
                                width: auto;
                            }
                        }
                        .type-proyect{
                            height: 1.5vw;
                            width: auto;
                            padding: .6vw 0.8vw;
                            background: var(--Secondary);
                            color: white;
                            font-size: .9vw;
                            @include flex-center();
                            white-space: nowrap;
                            margin-left: auto;
                            position: absolute;
                            bottom: 1vw;
                            right: 0;
                        
                        }
                    }
                    .img-build{
                        height: auto;
                        width: 100%;
                        display: flex;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        img{
                            height: auto;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    .info-build{
                        height: 9.5vw;
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: column;
                        background: #F6F6F6;
                        padding-left: 1vw;
                        .img_info_build{
                            height: 39%;
                            width: 100%;
                            background-size: 100% auto;
                            background-repeat: no-repeat;
                            background-position: 0% 84%;

                        }
                        .paragraph{
                            height: 6.5vw;
                            width: 100%;
                            flex-direction: column;
                            display: flex;
                            justify-content: center;
                            .direccion,.dorms,.attributes{
                                font-family: "GtWalsheimProMedium";
                                display: block;
                                font-size: 1.12vw;
                                color:var(--Secondary)
                            }
                            .bold{
                                font-family: "GtWalsheimProBold";
                            }
                        }
                    }
                    .logo{
                        position: absolute;
                        left: -7%;
                        top: 3%;
                        right: 0;
                        margin: auto;
                        height: 20%;
                    }
                    .p-none{
                        display: none;
                    }
                }
                .build{
                    box-shadow: 0px 0px 20px 3px #e2e2e2;
                }
                .build:hover{
                    .button-look{
                        background: var(--Secondary);
                        color: white;
                    }
                }
            }
            .items:hover{
                transform: scale(1.02);
            }
            .items .button-look{
                position: absolute;
                width: 100%;
                height: 3vw;
                @include flex-center();
                bottom: 0;
                right: 0;
                color: var(--dark);
                background: var(--Primary);
                transition: .2s linear;
                cursor: pointer;
                font-size: 1.2vw;
                font-family: "GtWalsheimProBold";
                font-weight: bold;
            }
            .button-look:hover{
                background: var(--Secondary);
                color: white;
            }
        }
    }
}
@media (max-width:700px){
    
    .Proyectos{
        .owl-stage{
            padding-top: 4vw;
            padding-bottom: 3vw;
        }
        .owl-nav{
            display: flex!important;
            .owl-prev,.owl-next{
                width: 12vw;
                height: 12vw;
                padding: 3.5vw 2.6vw;
                background: white;
                box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.623);
            }
            .owl-prev{
                left: 22%;    
            }
            .owl-next{
                right:22%;
                
            }
        }
        .Content_proyectos{
            height: auto;
            overflow: hidden;
            .selectores{
                #todos{
                    display: block;
                }
                #todos-desktop{
                    display: none;
                }
                .title{
                    font-size: 6.5vw;
                    padding-top: 8vw;
                    padding-bottom: 4.5vw;
                    font-family: "GtWalsheimProBold";
                }
                .selector{
                    font-size: 2.05vw!important;
                    user-select: none;
                }
            }
            .builds{
                justify-content: space-evenly;
                padding: 0;
                display: block;
                width: 150vw;
                position: relative;
                left: -25vw;
                margin-bottom: 14vw;
                .items{
                    user-select: none;
                    height: auto;
                    width: 100%;
                    margin:0;
                    box-shadow: 0px 0px 20px 1px rgba(173, 173, 173, 0.562);
                    @media(max-width:900px) and (min-width:700px){
                        padding: 4vw;
                        box-shadow: none;
                    }
                    .build{
                        .build-header{
                            height: 18vw;
                            padding: .5vw 0 .8vw 9vw;
                            .type-proyect{
                                height: 6vw;
                                font-size: 3vw!important;
                                padding: 0.55vw 1.4vw 0.4vw 1.4vw!important;
                            }
                        }
                        .info-build{
                            height: 31.5vw;
                            padding-left: 3vw;
                            .type-proyect::after{
                                font-size: 1.5vw!important;;
                            }
                            .paragraph{
                                height: 19.5vw;
                                .direccion,.dorms,.attributes{
                                    font-size: 3.8vw;
                                }

                            }
                            .button-look{
                                font-size: 3.8vw;
                                height: 9vw;
                            }
                        }
                    }
                }
            }
        }
        
    }
}