@mixin flex-center{
    display: flex;
    align-items: center;
    justify-content:center;
}
@keyframes move{
    0%{width: 0%;}
    100%{width: 100%;}
}

.animate-in{
    animation: ingreso 1s forwards 2s;
}
.animate-out{
    animation: salida 2s forwards 5s;
}
@keyframes ingreso{
    0%{
        opacity: 0.9;
    }
    100%{
        opacity: 1;
    }
}
@keyframes salida{
    0%{
        opacity: 0.9;
    }
    100%{
        opacity: 1;
    }
}

.Inicio{
    height: 51vw;
    position: relative;
    width: 100%;
    overflow: hidden;
    .Slider-diap{
        height: 100%;
        width: 100%;
        position: relative;
        .estirar{
            position: absolute;
            height: 100%;
            width: 100%; 
            overflow: hidden;
        }
    }
    .background-black{
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.532);
        position: relative;
        z-index: 1;
    }
    .background{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        .img-background{
            width: 100%;
        }
        .title{
            height: 100%;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            color: white;
            .background-brand{
                height: 17.7vw;
                margin-bottom: 2.3vw;
                @include flex-center();
                flex-direction: column;
                .mivivienda{
                    width: 5vw;
                }
                .construyendo{
                    background: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 9.1vw;
                    height: 1.8vw;
                    font-size: .75vw;
                    font-weight: bold;
                    color: #4E4E50;
                    margin: 1vw 0;
                    font-family: "MarkProHeavy";
                    padding-top: .2vw;
                }
                .noubrasil{
                    width: 22vw;
                }   
            }
            h3{
                font-size: 3.6vw;
                text-align: center;
                width: 52vw;
                color: white;
                font-family: "MarkProHeavy";
                margin-bottom: 2.5vw;
                letter-spacing: .2vw;
            }
            .btn-contacto{
                background: var(--Primary);
                width: 11.5vw;
                height: 3.3vw;
                color: white;
                font-size: 1.3vw;
                font-family: 'MarkProBold';
                @include flex-center();
                margin-bottom: 4vw;
                cursor:pointer;
                user-select: none;
            }
        }
    }
    .background::before{
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.541);  
    }
    .imagenes-miniatura{
        height: 8vw;
        width: auto;
        position: absolute;
        left: 8vw;
        bottom: 5vw;
        z-index: 2;
        .title-miniatura{
            color: white;
            font-size: 1.3vw;
            margin-bottom: .9vw;
        }
        .item-minuatura{
            height: 100%;
            width: 11vw;
            position: relative;
            margin-right: 1vw;
            cursor: pointer;
            .miniatura{
                height: 100%;
                width: 100%;
                object-fit: cover;
                margin-right: 1vw;
            }
            .sombra{
                height: 100%;
                width: 100%;
                position: absolute;
                background: rgba(0, 0, 0, 0.521);
                top: 0;
            }
            .timer{
                position: absolute;
                height: 0.3vw;
                width: 100%;
                bottom: 0;
                left:  0;
                .background-white{
                    height: 100%;
                    width: 0;
                    background: var(--Primary);
                }
            }
        }
        .item-minuatura.active{
            .sombra{
                background: transparent;
            }
            .timer{
                background: rgba(255, 255, 255, 0.699);
                .background-white{
                    animation: timing 7s forwards linear;
                }
            }
            @keyframes timing{
                0%{
                    width: 0%;
                }
                100%{
                    width: 100%;
                }
            }
        }
    }
    .title-slider{
        position: absolute;
        height: 10vw;
        top: 0;
        bottom: 2vw;
        left: 8vw;
        margin: auto;
        z-index: 3;
        display: flex;
        flex-direction: column;

        .title-main{
            font-size: 6vw;
            color: white;
        }
        .subtitle-main{
            font-size: 2vw;
            color: white;
            position: relative;
            top: -.5vw;
        }
        .more-information{
            color: var(--Primary);
            display: flex;
            align-items: center;
            .information-signal{
                height: 1vw;
                width: 2vw;
                svg{
                    width:100%;
                    height:100%
                }
            }
        }

    }
    .contact-slider{
        position: absolute;
        right: 4vw;
        bottom: 4vw;
        z-index: 3;
        .telefono,.direccion{
            .title-contact-slider{
                font-weight: bold;
            }
        }
        .telefono{
            margin-right: 3vw;
        }
        span{
            font-family: "GtWalsheimProLight";
            font-weight: 100;
            color: white;
            font-size: 0.9vw;
        }
    }
    .leyenda{
        height: 7vw;
        width: 4.5vw;
        position: absolute;
        top: 2.8vw;
        bottom: 0;
        left: 2vw;
        margin: auto;
        z-index: 998;
        .item{
            width: 80%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            cursor: pointer;
            color: white;
            font-size: 1.2vw;
            opacity: .3;
            .contador{
                height: 1.5vw;
                width: 1.5vw;
                min-width: 1.5vw;
                margin-bottom: .5vw;
                .num{
                    width: 100%;
                    height: 70%;
                    color: var(--Third);
                    @include flex-center();
                    user-select: none;
                    font-size: 1.5vw;
                }
                .barra{
                    height: 14%;
                    width: 85%;
                    @include flex-center();
                    background: grey;
                    margin: auto;
                    position: relative;
                }
                
                @keyframes change-image{
                    0%{transform: scaleX(1);}
                    100%{transform: scaleX(0);}
                }
            }
            .item-title{
                color: var(--Third);
                line-height: 1.2vw;
                margin-left: .7vw;
                font-family: "MarkPro";
                letter-spacing: .05vw;
                .main-title{
                    font-family: "MarkProMedium";
                    margin-bottom: 0;
                }
            }
        }
        .event{
            opacity: 1;
            user-select: none;
            .num,.item-title{
                user-select: none;
                opacity: 1;
            }
            .barra::after{
                content: "";
                display: flex;
                height: 100%;
                width: 100%;
                position: relative;
                transform: scaleX(1);
                transform-origin: 100% 0%;
            }
            .active::after{
                background: var(--Primary);
                animation: 7s change-image infinite linear;
            }
        }
    }
    
}

@media (orientation:portrait),(max-width:700px){
    .Inicio{
        height: 171VW;
        .estirar{
            .background{
                width: 100vw;
                .img-background{
                    width: 100%;
                    object-fit: cover;
                }
                .p-absolute{
                    padding-bottom: 14vh;
                    .background-brand{
                        height: 59.7vw;
                        margin-bottom: 4vw;
                        .mivivienda{
                            width: 23vw;
                        }
                        .construyendo{
                            width: 34vw;
                            height: 8.9vw;
                            margin: 4vw 0;
                            font-size: 2.5vw;
                        }
                        .noubrasil{
                            width: 39vw;
                        }
                    }
                    .title-main{
                        font-size: 7vw;
                        width: auto;
                        margin-bottom: 4vw;
                    }
                    .btn-contacto{
                        width: 33vw;
                        height: 10.3vw;
                        font-size: 4vw;
                        bottom: -5.15vw;
                        user-select: none;
                    }
                }
            }
        }
        .title-slider{
            height: 50vw;
            left: 0;
            width: 100%;
            .title-main{
                font-size: 20vw;
                display: flex;
                justify-content: center;
            }
            .subtitle-main{
                font-size: 7vw;
                display: flex;
                justify-content: center;
            }
            .more-information{
                font-size: 7vw;
                justify-content: center;
                .information-signal{
                    height: 6vw;
                    width: 5vw;
                    svg{
                        margin-left: .5rem;
                    }
                }
            }
        }
        .contact-slider{
            display: none;
        }
        .controllers{
            .main-nav{
                position: absolute;
                top: 0;
                bottom: 0;
                width: 6vw;
                margin: auto;
                height: 10vw;
                z-index: 3;
                svg{
                    height: 100%;
                }
            }
            .prev{
                left: 4vw;
            }
            .next{
                right: 4vw;
            }
        }

        .leyenda{
            background: transparent;
            bottom: 10vw;
            top: auto;
            left: 0;
            right: 0;
            margin: auto;
            width: 60vw;
            height: auto;
            display: flex;
            justify-content: center;
            bottom: 13vh;
            .item{
                background: transparent;
                justify-content: center;
                width: 11vw;
                height: 11vw;
                margin: 1vw;
                .contador{
                    user-select: none;
                    width: 100%;
                    height: 70%;
                    align-items: flex-end;
                    font-size: 3vw;
                    margin-bottom: 0;
                    padding-top: .5vw;
                    .num{
                        font-size: 8vw;
                        color: white;
                    }
                    .barra{
                        width: 4vw;
                    }
                }
                .item-title{
                    display: none;
                }
            }
        }
        .imagenes-miniatura{
            display: none;
        }
    }
    
}

#point-1{
    animation: .8s chat infinite alternate linear .5s;
}
#point-2{
    animation: .8s chat infinite alternate linear .7s;
}
#point-3{
    animation: .8s chat infinite alternate linear 1s;
}
@keyframes chat{
    0%{
        transform: translateY(2.5%);
    }
    40%{
        transform: translateY(-2.5%);
    }
    100%{
        transform: translateY(-2.5%);
    }
}