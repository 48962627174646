.Slider-tipologias{
    .img-none{
        display: none;
    }
    .content-slider{
        border-radius: 3vw;
        background: #F0F0F0;
        padding: 0 9vw;
        margin-bottom: 9vw;
    }
    .owl-nav{
        display: block;
    }
    .owl-prev,.owl-next{
        width: 8vw;
        height: 8vw;
        cursor: pointer;
        background: black;
        padding: 2.5vw 0vw;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
    }
    .owl-prev{
        left: -12.5%;
        border-radius: 0 1.5vw 1.5vw 0;
    }
    .owl-next{
        right: -12.5%;
        border-radius: 1.5vw 0 0 1.5vw;
    }
    .owl-next:hover,.owl-prev:hover{
        transform: scale(1.03);
        box-shadow:
             0 0 6px -1px rgba(0, 0, 0, 0.185), 
             0 0 10px -2px rgba(0, 0, 0, 0.404), 
             0 0 20px -5px rgba(0, 0, 0, 0.603);
        polyline{
            stroke: var(--green)!important;
        }
    }
    .tipologia{
        width: 100%;
        height: 31vw;
        // min-width: 30vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: .5;
        transition: 0.5s;
        cursor: pointer;
        .img-tipologia{
            width: 100%;
            padding: 0 .5rem;
            height: 55%;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            user-select: none;
            img{
                width: 100%;
                height: 61%;
                object-fit: contain;
            }
        }
        .content-info{
            display: flex;
            flex-direction: column;
            align-items: center;
            pointer-events: none;
            .tipo{
                font-size: 4.2vw;
                font-weight: bold;
                font-family: GtWalsheimProBold;
            }
            .meters{
                font-size: 4vw;
            }
        }
    }
    .tipologia:hover{
        opacity: .8;
        transform: scale(1.03);
    }
    .tipologia.active{
        opacity: 1;
    }
    .tipologia-main{
        width: 100%;
        .signal{
            width: 17vw;
            height: 4vw;
            mask: url("../../Assets/images/iconos/svg/signal-tipologia.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            background: var(--textpage);
            margin-left: auto;
        }
        .img-main-tipologia{
            position: relative;
            .img{
                width: 100%;
                margin-bottom: 20vw;
                user-select: none;
            }
            .img.third{
                // width: 25rem;
                position: relative;
                width: 25rem;
                @media(max-width:740px){
                    width: 78%;
                    align-self: center;
                    left: 10%;
                }
            }
            .third-flat{
                width: 15rem;
                height: 2.5rem;
                position: absolute;
                background: var(--text);
                bottom: -2vw;
                color: white;
                display: none;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding-right: 1rem;
                padding-bottom: .2rem;
                @media(max-width:740px){
                    width: 46vw;
                    height: 8.5vw;
                    padding-right: 1rem;
                    padding-bottom: .2rem;
                    right: 0;
                    bottom: 8vw;
                }
                .text{
                    color:white;
                    font-size: 1rem;
                    @media(max-width:740px){
                        font-size: .8rem;
                    }
                }
                .signal{
                    position: absolute;
                    width: 3rem;
                    background: white;
                    right: .6rem;
                    margin-top: 0.2rem;
                    @media(max-width:740px){
                        width: 7vw;
                    }
                }
            }
            .third-flat.active{
                display: flex;
            }
            .third-flat::before{
                content: "";
                position: absolute;
                width: 2.5rem;
                height: .1vw;
                left: 0;
                top: 0;
                bottom: 0;
                background: rgba(255, 255, 255, 0.575);
                margin: auto;
                @media(max-width:740px){
                    width: 5vw;
                }
            }
        }
        .content-third{
            right: 7rem;
            @media(max-width:740px){
                right: 0;
            }
        }
        .title-main-tipologia{
            position: absolute;
            bottom: 0;
            font-size: 18vw;
            left: 0;
            font-family: GtWalsheimProBold;
            font-weight: bold;
            opacity: 0.2;
        }
        .content-info-tipologia{
            position: relative;
            background: #F0F0F0;
            display: flex;
            flex-wrap: wrap;
            padding: 4vw 10vw 8vw 7vw;
            .item{
                width: 50%;
                padding: 2vw 0;
                display: flex;
            }
            .area{
                display: flex;
                flex-direction: column;
                .title-1{
                    font-size: 4vw;
                    user-select: none;
                }
                .title-2{
                    font-size: 7vw;
                }
            }
            .map{
                position: relative;
                img{
                    width: 100%;
                    position: absolute;
                    bottom: 2vw;
                    left: 6vw;
                }
            }
            .dorms,.bath{
                img{
                    width: 6vw;
                    margin-right: 2vw;
                }
            }
            .bath{
                display: flex;
                justify-content: flex-end;
            }
            .title-item{
                font-size: 4.1vw;
                line-height: 1;
            }
            .cotizar{
                position: absolute;
                height: 12vw;
                width: 77%;
                background: var(--maincolor);
                bottom: -5vw;
                display: flex;
                align-items: center;
                padding: 1vw 5vw;
                cursor: pointer;
                transition: .5s;
                .bold{
                    color: var(--textpage);
                    font-size: 5vw;
                }
            }
            .cotizar:hover{
                transform: scale(1.02);
                box-shadow: 0 4px 10px -4px #80808014, 0 6px 10px -2px #9c9c9c1a, 0 8px 10px -2px #0000001f;
            }
        }
    }
}
@media(min-width:700px){
.Slider-tipologias{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8rem;
    position: relative;
    @media(min-width:960px){
        margin-bottom: 9rem;
        
    }
    .owl-next,.owl-prev{
        width: 2rem;
        height: 2rem;
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .owl-prev{
        border-radius: 0 .3rem .3rem 0;
        left: -2.5rem;
    }
    .owl-next{
        right: -2.5rem;
        border-radius: .3rem 0 0 .3rem ;
    }
    .title{
        align-items: center;
        padding-bottom: .5rem;

        align-items: center;
        justify-content: center;
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem!important;

        @media(min-width:960px){
            margin-bottom: 9rem!important;
            margin-right: 3rem;
            align-items: center;
            justify-content: flex-start;
            width: auto;
        }
    }
    .content-slider{
        border-radius: .8rem;
        background: white;
        border: 1px solid #02020214;
        padding: 0 2.5rem;
        width: 100%;
        @media(min-width:960px){
            width: 38rem;
        }
        @media(min-width:1200px){
            width: 46rem;
        }
        margin-left: auto;
    }
    .tipologia{
        width: 100%;
        height: 8rem;
        .content-info{
            .tipo,.meters{
                font-size: 1rem;
            }
        }
    }
    .tipologia-main{
        position: relative;
        .img-main-tipologia{
            position: absolute;
            z-index: 3;
            top: -2rem;
            right: 1rem;
            @media(min-width:960px){
                top: -7rem;
                right: 2rem;
            }
            @media(min-width:1200px){
                top: -7rem;
                right: 4rem;
            }
            .img{
                width: 19rem;
                @media(min-width:960px){
                    width: 29rem;
                }
                @media(min-width:1200px){
                    width: 33rem;
                }
                filter: drop-shadow(0.2rem 1.2rem 1rem rgba(0,0,0,0.3));
                margin-bottom: 0;
            }
        }
        .title-main-tipologia{
            position: absolute;
            top: -4rem;
            height: 5rem;
            font-size: 3rem;
        }
        .container-info-tipologia{
            width: 100%;
            background: #F0F0F0;
            height: auto;
            padding-bottom: 1rem;
            @media(min-width:960px){
                padding-bottom: 1rem;
                padding-top: 1rem;
                padding-left: 1rem;
            }
        }
        .content-info-tipologia{
            width: 49%;
            padding: 1rem 1.5rem;
            height: 11rem;
            @media(min-width:960px){
                width: 40%;
            }
          
            .item{
                padding: 1rem 0;
            }
            .area{
                height: 4rem;
                padding: 0;
                .title-1{
                    font-size: 1.1rem;
                }
                .title-2{
                    font-size: 1.5rem;
                    @media(min-width:960px){
                        font-size: 2rem;
                    }
                }
            }
            .map{
                img{
                    bottom: 0;
                    left: 21%;
                    filter: drop-shadow(0.1rem .7rem 1rem rgba(0, 0, 0, 0.1));
                }
            }
            .title-item{
                font-size: 1rem;
                align-items: center;
                display: flex;
                @media(min-width:960px){
                    font-size: 1rem;
                }
                @media(min-width:1200px){
                    font-size: 1.3rem;
                }
            }
            .dorms,.bath{
                img{
                    width: 2rem;
                    margin-right: .5rem;
                }
            }
            .cotizar{
                height: 26%;
                width: 75%;
                padding: 1rem 1.5rem;
                bottom: -2.3rem;
                left: 0;
                right: 0;
                margin: auto;
                .bold{
                    font-size: 1.4rem;
                }
                .signal{
                    width: 31%;
                    height: 93%;
                }
            }
        }
    }
    
}
}
