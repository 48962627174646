.footer{
    height: 12.2vw;
    width: 100%;
    .movil-footer{
        display: none;
    }
    .copyright{
        display: none;;
    }
    .words{
        color: white;
        @media(max-width:500px){
            font-size: .8rem;
        }
    }
    .link-icon{
        width: 1.2rem;
        height: 1.2rem;
        margin-right: .7rem;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    .column{
        width: 100%;
        height: 100%;
        background: #011828;
        display: flex;
        padding: 0 11.2vw;
        .brands{
            width: 28.8vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .brand{
                width: 100%;
                height: auto;
                display: flex;            
                svg{
                    width: 12.8vw;
                    margin-left: 7.7vw;
                    padding-top: 2.8vw;
                }
                img{
                    width: auto;
                    height: 4vw;
                }
            }
            .brand1{
                width: 100%;
                height: auto;
                display: flex;   
                padding-top: .5vw;         
                svg{
                    width: 8.5vw;
                    margin-left: 7.7vw;
                }
                .paragraph-brand{
                    font-size: 1vw;
                    @media(min-width:1200px){
                        font-size: .6rem;
                    }
                    @media(min-width:1600px){
                        font-size: .7rem;
                    }
                    width: 100%;
                    font-family: "GtWalsheimProLight";
                }
            }
        }
        .footer-column{
            height: 100%;
            padding-top: 2.9vw;
            color: var(--Secondary);
            h5{
                font-size: 1.1vw;
                margin-bottom: .9vw;
                color: white;
            }
            .words{
                font-size: 1.1vw;
                margin-bottom: .25vw;
                display: flex;
                color: rgba(255, 255, 255, 0.692);
                .icono{
                    min-width: 1.2vw;
                    height: 1.2vw;
                    width: 1.2vw;
                    display: flex;
                    margin-right: .7vw;
                    svg{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        .item1{width: 30%;}
        .item2{
            width: 29%;
            h5{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                .link{
                    top: .2vw;
                    position: relative;
                    margin: 0 .3rem;
                    width: 1.5rem;
                }
            }
        }
        .item3{width: 17%;padding-right: 4.1vw;}
        .item4{width: 18vw;}
    }
}
@media(max-width:700px){
    .footer{
        .copyright{
            display: block;
        }
        .column{
            display: none;
        }
        .movil-footer{
            display: block;
            background: #011828;
            padding: 7vw 11vw;
            .link-brand{
                width: 100%;
                height: auto;
                justify-content: space-between;
                margin: 3vw 0;
                .brand{
                    height: 14vw;
                    img{
                        height: 100%;
                        width: auto;
                    }
                }
                .redes-sociales{
                    align-items: center;
                    .link{
                        height: 4vw;
                        width: 4vw;
                        margin: 0 1vw;
                        svg{
                            width:100%;
                            height: 100%;
                        }
                    }
                }
                .information{
                    .title-direccion,.title-phone{
                        color: white;
                        font-size: 4vw;
                        margin-bottom: 2vw;
                    
                    }
                    .direccion,.phone{
                        color: rgba(255, 255, 255, 0.616);
                        font-size: 3vw;
                    }
                }
            }
            .copyright{
                color: white;
                font-size: 3vw;
                text-align: center;
                border-top: 1px solid white;
                padding-top: 6vw;

                margin-top: 6vw;
            }
        }
    }
}