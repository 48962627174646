:root{
    --Primary: #41CEAC;
    --Secondary: #00224E;
    --dark:#141414;
    --navbar:"hidden";
    --green:#41CEAC;
    --azul:#00224E;
    --gray:#808080;
} 
.modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.37);
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    --color:black;
    .overflow::-webkit-scrollbar {
        width: 10px;     /* Tamaño del scroll en vertical */
        height: 10px;    /* Tamaño del scroll en horizontal */
    }
    .overflow{
        padding-right: .5rem;
        &::-webkit-scrollbar-thumb{
            // display: none;
        }
    }
    .overflow::-webkit-scrollbar-thumb:active {
        background-color: #999999;
        
    }

    .overflow::-webkit-scrollbar-track {
        background: rgb(235, 235, 235);
        border-radius: 4px;
    }
    .content-modal{
        width: 100%;
        max-width: 38rem;
        margin-left: auto;
        margin-right: auto;
        height: 59vh;
        box-sizing: border-box;
        display: block;
        background-color: #fff;
        position: relative;
        padding: 45px;
        box-shadow: 0px 0px 10px -4px #0000003d;
        .overflow{
            overflow-y: scroll;
            height: 93%;
            &::-webkit-scrollbar-thumb {
                animation: .5s animateScroll forwards;
                background-color: rgb(155, 155, 155);
                opacity: .5;
                border-radius: 4px;
                position: fixed;
            }
    
            &::-webkit-scrollbar {
                width: 6px;     /* Tamaño del scroll en vertical */
                height: 6px;    /* Tamaño del scroll en horizontal */
            }
        }
        h2{
            font-size: 1.4rem;
            font-weight: 700;
            color: var(--color);
            letter-spacing: .2px;
            line-height: 1em;
            display: block;
            box-sizing: border-box;
            padding-bottom: 15px;
        }
        .sub-title{
            font-size: 1.1rem;
        }
        .ball{
            width: 8px;
            min-width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: black;
            display: inline-block;
            margin-right: .5rem;
        }
        p{
            font-size: 1rem;
            line-height: 1.5em;
            color: var(--color);
            display: block;
            box-sizing: border-box;
            margin-bottom: 1rem;
        }
        ul,li{
            list-style:circle;
            font-size: 1rem;
            margin-bottom: 1rem;
        }
        li{
            display: flex;
            align-items: center;

        }
        a{
            color: var(--color);
            text-decoration: underline;
        }
        .close{
            mask-image: url("../../images/iconos/icon-close.svg");
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background: #b8b6b5;
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
            cursor: pointer;
        }
        // box-shadow: 0 8px 46px rgb(0,0,0, 0.8), 0 2px 6px rgb(0,0,0,0.3);
    }
}
.modal.active{
    display: flex;
}
//Sliders
.owl-item{
    // width: auto!important;
}
.lg-autoplay-button{
    display: none!important;
}
.swal2-styled.swal2-confirm {
    background-color: var(--Secondary) !important;
}
.owl-nav{
    display: none;
    .owl-prev,.owl-next{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 3vw;
        height: 3vw;
        
        svg{
            height: 100%;
        }
    }
    .owl-prev{left: 5%;cursor: pointer;}
    .owl-next{right: 5%;cursor: pointer;}
}
.owl-stage{
    display: flex;
    flex-direction: row;
    height: 100%;
}
.owl-stage-outer{
    overflow: hidden;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.owl-carousel{
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "aldrich";
}
// end Slider
.Section{
    width: 100%;
    height: var(--height);
    min-height: 700px;
    position: relative;
}
.flex-row{
    display: flex;
    flex-direction: row;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.text-center{
    text-align: center;
}
.p-absolute{
    position: absolute;
}
.mb-1{
    margin-bottom: 1vw;
}
.mr-1{
    margin-right: .5vw;
}
.img-expand{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.desktop{
    display: none!important;
}
.movil{
    display: block!important;
}
@media(min-width:700px){
    .desktop{
        display: flex!important;
    }
    .movil{
        display: none!important;
    }
}
.lg-thumb-item.active{
    border-color: var(--Primary)!important;
}
//Colores
.Primary{
    color: var(--Primary);
}
.Secondary{
    color: var(--Secondary);
}
.white{
    color: white;
}

//
.pointer{
    cursor: pointer;
}
.img-fluid{
    width: 100%;
}

.d-block{
    display: block;
}
.mx-auto{
    margin: 0 auto 0 auto;
}
// style size dimentions
.container{
    padding: 0 1rem;
    @media(min-width:720px){
        max-width: 720px;
    }
    @media(min-width:960px){
        max-width: 960px;
    }
    @media(min-width:1200px){
        max-width: 1200px;
    }
}
// iconos
.left{
    mask: url("../../../Assets/images/iconos/signalLeft.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}
.right{
    mask: url("../../../Assets/images/iconos/signalRight.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}