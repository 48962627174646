@font-face {
    font-family: "GtWalsheimProLight";
    font-display: swap;
    src: url("../fonts/GTWalsheimProLight.woff2") format("woff2");
}

@font-face {
    font-family: "GtWalsheimProRegular";
    font-display: swap;
    src: url("../fonts/GTWalsheimProRegular.woff2") format("woff2");

}

@font-face {
    font-family: "GtWalsheimProMedium";
    src: url("../fonts/GTWalsheimProMedium.woff2");

}
@font-face {
    font-family: "GtWalsheimProBold";
    src: url("../fonts/GTWalsheimBold.woff2");
}
@font-face {
    font-family: "MyriadPro-Regular";
    src: url("../fonts/GTWalsheimProRegular.woff2");
}

.AntebBlackItalic{font-family: "AntebBlackItalic";}
.AntebBoldItalic{font-family: "AntebBoldItalic";}
.AntebItalic{font-family: "AntebItalic";}
.AntebLight{font-family: "AntebLight";}
.AntebLightItalic{font-family: "AntebLightItalic";}
.AntebMediumItalic{font-family: "AntebMediumItalic";}
.AntebSemiLightItalic{font-family: "AntebSemiLightItalic";}
.Arial{font-family: "Arial";}
.TextAltBlackItalic{font-family: "TextAltBlackItalic";}
.GtWalsheimProLight{font-family: "GtWalsheimProLight";}
.GtWalsheimProRegular{font-family: "GtWalsheimProRegular";}
.GtWalsheimProMedium{font-family: "GtWalsheimProMedium";}
.GtWalsheimProBold{font-family: "GtWalsheimProBold";}
.MyriadPro-Regular{font-family: "MyriadPro-Regular";}
.bold{
    font-family: "GtWalsheimProBold";
}
