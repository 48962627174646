@mixin flex-center{
    display: flex;
    align-items: center;
    justify-content:center;
}
@keyframes move{
    0%{width: 0%;}
    100%{width: 100%;}
}

.Slider-main-model{
    height: 51vw;
    position: relative;
    .Slider-main{
        width: 100%;
        height: 51vw;
        display: flex;
        overflow: hidden;
        .info-content{
            display: flex;
            width: 50%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .info{
                display: flex;
                flex-wrap: wrap;
                width: 33vw;
                .brand{
                    height: 9vw;
                    width: 9vw;
                    box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.192);
                    border-radius: 1vw;
                    padding: 0 1vw;
                    display: flex;
                    align-items: center;
                    img{
                        width: 100%;
                    }
                }
                .address{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 2vw;
                    .title-1{
                        font-size: 1.3vw;
                        letter-spacing: .4vw;
                    }
                    .title-2{
                        font-size: 1.9vw;
                    }
                    .title-3{
                        font-size: 1.6vw;
                    }
                }
            }
        }
        .cotizar{
            width: 20vw;
            height: 3.5vw;
            border-radius: 50rem;
            background: var(--maincolor);
            display: flex;
            align-items: center;
            padding: 0 1.8vw;
            font-weight: bold;
            font-size: 1.5vw;
            color: var(--textpage);
            margin-top: 2vw;
            transition: 1s;
            cursor: pointer;
            .signal{
                width: 4vw;
                height: 100%;
                mask: url("../../Assets/images/iconos/svg/signal-tipologia.svg");
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                background: var(--textpage);
                margin-left: auto;
            }
        }
        .cotizar:hover{
            transform: scale(1.02);
            box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.301);
            // .signal{
            //     animation:2s moveXsignal infinite;
            // }
        }
        @keyframes moveXsignal{
            0%{
                transform: translateX(0px);
                opacity: 1;
            }
            80%{
                opacity: 1;
            }
            100%{
                transform: translateX(25%);
                opacity: 0;
            }
        }
        .slide-render{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
                width: 65%;
            }
        }

    }
    .background{
        position: absolute;
        display: flex;
        width: 100vw;
        height: 100%;
        position: relative;
        z-index: 1;
        overflow: hidden;
        .img-background{
            width: 100%;
        }
        .title{
            height: 100%;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .background-brand{
                height: 17.7vw;
                margin-bottom: 2.3vw;
                @include flex-center();
                flex-direction: column;
                .mivivienda{
                    width: 5vw;
                }
                .construyendo{
                    background: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 9.1vw;
                    height: 1.8vw;
                    font-size: .75vw;
                    font-weight: bold;
                    color: #4E4E50;
                    margin: 1vw 0;
                    font-family: "MarkProHeavy";
                    padding-top: .2vw;
                }
                .noubrasil{
                    width: 22vw;
                }   
            }
            h3{
                font-size: 3.6vw;
                text-align: center;
                width: 52vw;
                color: var(--Secondary);
                font-family: "MarkProHeavy";
                margin-bottom: 2.5vw;
                letter-spacing: .2vw;
            }
            .btn-contacto{
                background: var(--Primary);
                width: 11.5vw;
                height: 3.3vw;
                color: white;
                font-size: 1.3vw;
                font-family: 'MarkProBold';
                @include flex-center();
                margin-bottom: 4vw;
                cursor:pointer;
                user-select: none;
            }
        }
    }
    .background::before{
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        // background: linear-gradient(0deg, rgba(255, 255, 255, 0.32) 0%, white 100%);
    }
    
    .leyenda{
        height: 3vw;
        width: max-content;
        background: transparent;
        position: absolute;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        padding: 1vw 0 1vw 1vw;
        display: flex;
        justify-content: center;
        z-index: 998;
        .item-model{
            width: 80%;
            height: 25%;
            display: flex;
            align-items: center;
            margin: 0 .8rem;
            font-size: 1vw;
            cursor: pointer;
            .contador{
                height: 2.5vw;
                width: 3.5vw;
                min-width: 1.5vw;
                margin-bottom: .5vw;
                border-radius: 1vw;
                .num{
                    width: 100%;
                    height: 70%;
                    font-family: "MarkProHeavy";
                    color: var(--Third);
                    @include flex-center();
                }
                .barra{
                    height: 14%;
                    width: 100%;
                    @include flex-center();
                    background: grey;
                    margin: auto;
                    position: relative;
                    overflow: hidden;
                    border-radius: 5rem;
                }
                
                @keyframes change-image{
                    0%{transform: scaleX(1);}
                    100%{transform: scaleX(0);}
                }
            }
            .item-title{
                color: var(--Third);
                line-height: 1.2vw;
                margin-left: .7vw;
                font-family: "MarkPro";
                letter-spacing: .05vw;
                .main-title{
                    font-family: "MarkProMedium";
                    margin-bottom: 0;
                }
            }
        }
        .event{
            .num,.item-title{
                color: var(--Primary)!important;
            }
            .barra::after{
                content: "";
                display: flex;
                height: 100%;
                width: 100%;
                position: relative;
                transform: scaleX(1);
                transform-origin: 100% 0%;
            }
            .active::after{
                background: var(--dark);
                animation: 7s change-image infinite linear;
            }
        }
    }
    
}

@media (orientation:portrait),(max-width:700px){
    .Slider-main-model{
        height: 204vw;
        .Slider-diap{
            height: 100%;
            .Slider-main{
                height: 100%;
                flex-direction: column;
                padding-top: 5rem;
                .brand{
                    align-items: center;
                    justify-content: center;
                    display: flex!important;
                    order: 1;
                    img{
                        width: 32vw;
                        height: 32vw;
                        object-fit: contain;
                    }
                }
                .slide-render{
                    order: 2;
                    width: 100%;
                    justify-content: center;
                    img{
                        width:76%
                    }
                }
                .info-content{
                    order: 3;
                    width: 100%;
                    justify-content: flex-start;
                    margin-top: 5vw;
                    .info{
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        .desktop{
                            display: none!important;
                        }
                        .title-1{
                            font-size: 6.3vw;
                            text-align: center;
                        }
                        .title-2{
                            text-align: center;
                            font-size: 7vw;
                        }
                        .title-3{
                            font-size: 6.5vw;
                            text-align: center;
                        }
                        .cotizar{
                            width: 70vw;
                            height: 11.5vw;
                            font-size: 6vw;
                            margin-top: 5vw;
                            padding: 0 6vw;
                            .signal{                
                                width: 17vw;
                            }
                        }
                    }
                }
            }
        }
        
        .leyenda{
            background: transparent;
            bottom: 10vw;
            top: auto;
            left: 0;
            right: 0;
            margin: auto;
            width: 60vw;
            height: auto;
            display: flex;
            justify-content: center;
            bottom: 1vw;
            .item-model{
                justify-content: center;
                width: auto;
                height: auto;
                margin: 1vw;
                padding: 3vw;
                .contador{
                    user-select: none;
                    width: 10vw;
                    height: 1vw;
                    align-items: flex-end;
                    margin-bottom: 0;
                    .barra{
                        width: 100%;
                        height: 100%;
                        border-radius: 2vw;
                    }
                }
                .item-title{
                    display: none;
                }
            }
        }
    }
}

#point-1{
    animation: .8s chat infinite alternate linear .5s;
}
#point-2{
    animation: .8s chat infinite alternate linear .7s;
}
#point-3{
    animation: .8s chat infinite alternate linear 1s;
}
@keyframes chat{
    0%{
        transform: translateY(2.5%);
    }
    40%{
        transform: translateY(-2.5%);
    }
    100%{
        transform: translateY(-2.5%);
    }
}