/* demo css */
*,
*::after,
*::before {
	box-sizing: border-box;
}





.content {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 13rem);
	position: relative;
	justify-content: flex-start;
	align-items: center;
	overflow-x: hidden;
}
/* plugin css */
.rgbKineticSlider {
	display: block;
    position: absolute;
    overflow: hidden;
    width: 100vw;
    min-height: 51vw;
	top: 0;
	background: black;
}
#rgbKineticSlider > .move{
	animation: move 3s forwards linear;
}
#rgbKineticSlider > .expand{
	animation: expand 3s forwards linear;
}


canvas {
    position: absolute;
	display : block;
	height: auto;
    top:0;
	left : 0;
	/* opacity: 0.5; */
}

@media(orientation:portrait){
	#rgbKineticSlider{
		min-height: 178vw;
	}
	.rgbKineticSlider canvas{
		min-height: 100%;
		width: 100%!important;
		top: 56%!important;
		left: 56%!important;
	}
}

